.teacher-header-schedule {
    height: 15vh;
    align-items: center;
    display: flex;   
    border-bottom: 1.3px solid rgb(220, 217, 217);
}

.logo-schedule {
    width: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-img {
    margin-bottom: 30px;
    max-width: 100%;
    max-height: 100%;
}

.menu {
    width: 20vw;
    background-color: #EAEAFF;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
}

.menu p {
    font-size: 12px;
}

.info {
    width: 60vw;
    padding-left: 1rem;
}

.info p {
    font-size: 26px;
}

.logout {
    margin-right: 2vw;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.icons-logout {
    color: #B1B1FF;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
}

.logout-button-teacher {
    border-radius: 12px;
    height: 40px;
    width: 120px;
    background-color: white;
    color: #B1B1FF;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 16px #B8B8ED33;
    border: 1px solid #B1B1FF;
}

.logout span {
    margin-left: 5px;
}

.under-logout{
    position:absolute;
    margin-top: 3rem;
    /* margin-right:5rem; */
    white-space: nowrap;
    
}