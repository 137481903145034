.main-student-container {
    border: 3px solid #B1B1FF;
    margin: 20px;
    border-radius: 20px;
}

.quiz-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
}

.quiz-page-info {
    width: 75%;
    margin-left: 2rem ;
    height: 60vh;
}

.quiz-page-info p{
    color: #B1B1FF;
    font-size: 8;
    margin-top: 3rem;
    text-align: center;
}

.past-quizzes-icon span {
    color: black;
    font-weight: bold;
    margin-left: 2rem;
    font-size: 12px;
}

.current-quizzes-icon span {
    color: black;
    font-weight: bold;
    margin-left: 2rem;
    font-size: 12px;
}

.past-quizzes-button {
    border-radius: 12px;
    height: 40px;
    width: 140px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 30px #B8B8ED33;
    border: 1px solid #B1B1FF;
}

.new-quiz-button {
    border-radius: 12px;
    height: 40px;
    width: 140px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 30px #B8B8ED33;
    border: 1px solid #B1B1FF;
}

/* .left-button-quiz-page {
    float: left;
    width: 30%;
}

.left-button-quiz-page-1 {
    float: left;
    width: 30%;
}

.right-button-quiz-page {
    width: 30%;
    float: right;
} */

.button-container {
    display:flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-evenly;
    align-items: center;
    bottom: 0;
    margin-top: 5rem;
}

.button-container-2 {
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    bottom: 0;
    margin-top: 5rem;
}

.no-quiz-info h4 {
    margin-top: 5rem;
    color: grey;
}

.no-quiz-info p {
    color:#B1B1FF;
    font-size: 8;
    margin-top: 3rem;
}

.icons-past {
    color: grey;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
}

.icons-current {
    color: grey;
    height: 3rem;
    width: 3rem;
    background-color: #F2F2FF;
    border-radius: 43%;
    text-align: center;
    line-height: 100px;
    padding: 1rem;
    margin-left: -0.8rem;
    margin-right: -0.4rem;


}

.icons-list {
    margin-top: 2rem;
}

.current-quizzes-icon:hover{
    text-decoration: none;
}

.past-quizzes-icon:hover {
    text-decoration: none;
}

.icons-help-contact {
    color: grey;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
}

.icons-logout {
    color: grey;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
}

.help-contact-icon span {
    color: black;
    font-weight: bold;
    margin-left: 2rem;
    font-size: 12px;
}

.logout-icon span {
    color: black;
    font-weight: bold;
    margin-left: 2rem;
    font-size: 12px;
}


.icons-list-bottom {
    margin-top: 12rem;
}




.table-quiz span {
    color:blue;
    font-weight: bold;
}

.table-crt {
    vertical-align: bottom;
    width: 20%;
}

.table-quiz {
    width: 60%;
}

.table-class-name{
    width: 40%;
}

.table-container {
    margin-top: 4rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

}

.start-quiz-button {
    border-radius: 0.3cm;
    height: 35px;
    width: 90px;
    background-color: rgb(49, 189, 49);
    color: white;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
}

.start-button {
    border-radius: 0.3cm;
    height: 35px;
    width: 90px;
    background-color: rgb(49, 189, 49);
    color: white;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    transition: all 0.25s;
} 
.start-button:hover{
    opacity: 80%;
}

.no-quiz-info {
    text-align: center;
    margin-top: 8%;
    font-size: 2vh;
    font-weight: bold;
    margin: auto;
    height: 60vh;
}