.teacher-quiz-container {
    min-height: 78vh;
    margin-top: 6vh;
    position: relative;
} 

.buttons-container {
    align-items: center;
    display: flex;
    width: 100%;
}

.back {
    width: 50%;
    margin-left: 5vw;
}

.dropdown {
    width: 50%;
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-right: 5vw;
}


.back-button-quizzes {
    border-radius: 12px;
    height: 40px;
    width: 160px;
    background-color: white;
    color: blue;
    font-weight: bold;
    font-size: 10px;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
}

.select-reports {
    border-radius: 12px;
    height: 40px;
    width: 160px;
    background-color: white;
    color: #0000ff;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
    cursor: pointer;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99975 14.167C9.78701 14.1659 9.57717 14.1175 9.38547 14.0253C9.19377 13.933 9.025 13.7992 8.89142 13.6337L5.38309 9.38366C5.17808 9.1278 5.04907 8.8195 5.01077 8.49388C4.97246 8.16827 5.02639 7.83844 5.16642 7.54199C5.27999 7.28435 5.46531 7.06484 5.70027 6.90968C5.93522 6.75452 6.20987 6.67027 6.49142 6.66699H13.5081C13.7896 6.67027 14.0643 6.75452 14.2992 6.90968C14.5342 7.06484 14.7195 7.28435 14.8331 7.54199C14.9731 7.83844 15.027 8.16827 14.9887 8.49388C14.9504 8.8195 14.8214 9.1278 14.6164 9.38366L11.1081 13.6337C10.9745 13.7992 10.8057 13.933 10.614 14.0253C10.4223 14.1175 10.2125 14.1659 9.99975 14.167Z" fill="%230000ff"/></svg>');
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position-x: 94%;
    background-position-y: 8px;
}

.quiz-table-container {
    margin-top: 2rem;
    padding-left: 5vw;
    padding-right: 4vh ;
    height: 30rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: 20;
}

.start-button-1 {
    border-radius: 12px;
    height: 32px;
    width: 90px;
    background-color: white;
    color: rgb(195, 194, 194);
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 16px #B8B8ED33;
    border: 1px solid #B8B8ED33;
}

.stop-button {
    border-radius: 12px;
    height: 32px;
    width: 90px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 5px red;
    border: 1px solid #B1B1FF;
}