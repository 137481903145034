#questions-popover {
   background: #ffffff;
   box-shadow: 5px 9px 14px rgba(0, 0, 0, 0.15),
      -2px 0px 5px rgba(0, 0, 0, 0.06);
   border: none;
   border-radius: 0;
   top: 13%;
   left: 64% !important;
}

.popover-header {
   background-color: #fff !important;
}

.last-popover {
   border: none !important;
}

.circle {
   width: 8px;
   height: 8px;
   border-radius: 50%;
}
.circle-approved {
   background-color: #6fcf97;
}
.circle-pending {
   background-color: #f2994a;
}
.circle-rejected {
   background-color: #eb5757;
}
.status-box {
   display: flex;
   align-items: center;
   justify-content: flex-start;
}
.status-text {
   margin-left: 11px;
}
