.class-code-div {
    margin-top: 20vh;
    display: flex;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.class-code-box {
    flex: 1;
}

.class-code-p {
    font-size: 35px;
    font-weight: bold;
}

.instructions {
    margin-top: 2vh;
    font-size: 23px;
    display: flex;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.main {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 20vh;
}

.box {
    flex: 1;
}

.form-title {
    font-size: 20px;
    font-weight: bold;
}

.button-student-registration {
    border-radius: 12px;
    height: 50px;
    width: 130px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 16px;
    color:grey;
    text-align: center;
    border: 2px solid #B1B1FF;
    float: right;
    margin-right: 13vw;
    margin-top: 2vh;
    box-shadow: 0px 0px 16px #B8B8ED33;
}

.save-button {
    width: 8vw;
    height: 5vh;
}

.dialog-title {
    text-align: center;
}

.celebration-icon {
    margin: auto;
    height: 30px;
    width: 30px;
    color: #9932CC;
    font-size: 40px;
}

.student-registration-page-container-form{
    display:flex;
    justify-content: center; 
    align-items: center;

}
@media screen and (min-height:501px) {
.student-registration-page-container-form{
    height:100vh;
}
}
@media screen and (min-width:700px) and (min-height:501px){
    .student-registration-page-container-content-form {
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        padding: 0 5%;
        
    }
    
}
@media screen and (max-width:400px){
    .student-registration-page-container-content-form {
        padding: 0 5%;
        
    }
}
.student-registration-page-container-continue-button {
    border: 1px solid #e5e2e2;
    font-family: Open Sans;
    border-radius: 23px;
    width: fit-content;
    background-color: inherit;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #b9b9b9;
    margin-bottom: 38px;
    height: 48px;
}

.student-registration-page-container-continue-button:hover {
    color: white;
}
.student-registration-page-container-content-form-style {
    width: 100%;
    /* min-width: 90vw; */
    padding: 2.5rem 0;
    max-width:400px;
}
@media screen and (min-width: 450px) {
    .student-registration-page-container-content-form-style {
        min-width: 400px;
    }
}
.student-registration-page-container-form-register-error{
    color:red;
    text-align: center;
}

.student-form-input-error{
    color:red;
    text-align: center;
    margin-bottom: 2.5rem;
}