.se-container {
   z-index: 0;
}

.css-2b097c-container {
   border-bottom: 1px solid #ced4da !important;
}

.css-11hpcgx-control,
.css-1hivdsp-control {
   border: none !important;
}
.css-1okebmr-indicatorSeparator {
   display: none !important;
}

.css-6q0nyr-Svg {
   color: black;
   height: 16px;
}

.css-1gi1mx2-ValueContainer {
   padding-left: 12px !important;
}
