.title-teacher {
    margin-top: 5vh;
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    margin-left: 20vw;
    width: 38%;
}

.messages {
    margin-left: 25vw;
    font-size: 22px;
    margin-top: 2vh;
    font-weight: 500;
    margin-bottom: 9vh;
}

.second-p {
    margin-left: 5vw;
}

.main {
    display: flex;
    width: 80%;
    margin: auto;
}

.box {
    flex: 1;
}

.form-title {
    font-size: 20px;
    font-weight: bold;
}

.button-div {
    border: 2px solid #9932cc;
    width: 8vw;
    height: 5vh;
    float: right;
    margin-right: 18vw;
    margin-top: 5vh;
    box-shadow: 6px 6px #9932cc;
}

.save-button {
    width: 8vw;
    height: 5vh;
}

.registration-page-container-form{
    display:flex;
    justify-content: center; 
    align-items: center;

}
@media screen and (min-height:501px) {
.registration-page-container-form{
    height:100vh;
}
}
@media screen and (min-width:700px) and (min-height:501px){
    .registration-page-container-content-form {
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        padding: 0 5%
    }
    
}

.registration-page-container-continue-button {
    border: 1px solid #e5e2e2;
    font-family: Open Sans;
    border-radius: 23px;
    width: fit-content;
    background-color: inherit;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #b9b9b9;
    margin-bottom: 38px;
    height: 48px;
}

.registration-page-container-continue-button:hover {
    color: white;
}
.class-creation-page-container-content-form-style {
    width: 100%;
    min-width: 90vw;
    padding: 2.5rem 0;
}
@media screen and (min-width: 450px) {
    .class-creation-page-container-content-form-style {
        min-width: 400px;
    }
}
.registration-page-container-form-register-error{
    color:red;
    text-align: center;
    max-width: 400px;
}