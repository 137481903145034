.class-retakes-info {
    width: 94%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin: 4rem;
    margin-top: 1.2rem;
    overflow: hidden;
    margin-left: 100px;
}

.selected-student.MuiTableRow-root.Mui-selected .MuiTableCell-root.MuiTableCell-body{
    background-color: #E6E6FA;
}

.class-retakes-info-standards{
    padding-right: 3rem;
}

.retakes-back-button {
    border-radius: 12px;
    height: 32px;
    width: 150px;
    background-color: white;
    color: blue;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
    margin-bottom: 3rem;
    margin-left: -18.5vw;
}

.retakes-table {
    width: 20%;
    float: left;
    margin-top: 5vh;
    height: 80vh;
    overflow-y: scroll;
}