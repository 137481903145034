.table-container {
    /* max-width: 1200px; */
    margin: 0 auto;
    /* min-width: 600px; */
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    max-height: 500px;
}
.table-container {
    border: 2px solid #dee2e6;
    scroll-behavior: smooth;
}
.table-header-cell {
    font-weight: bold !important;
    border-bottom: 2px solid #dee2e6 !important;
}

.table-body-cell {
    text-align: center;
    min-width: 125px;
    font-size: 1rem !important;
}

.cell-border-left {
    border-inline-start: 1px solid #dee2e6;
}

.save-changes-button {
    margin-top: 1rem !important;
    box-shadow: 0px 0px 4px #b1b1ff;
    border: 1px solid #b1b1ff !important;
    color: blue !important;
    font-weight: bold !important;
    font-size: 10px !important;
    width: 100%;
    max-width: 200px;
    border-radius: 12px !important;
    height: 40px;
    background-color: white;
}

@media screen and (min-width: 601px) and (max-width: 1400px) {
    .save-changes-button {
        min-width: 140px;
        max-width: 140px;
    }
}
@media screen and (min-width: 601px) {
    .save-changes-button{
        font-size:12px !important;
    }
}
.student-remove-button {
    /* margin-top:1rem !important; */
    box-shadow: 0px 0px 4px rgb(255, 163, 163) !important;
    border: 1px solid rgb(255, 154, 154) !important;
    color: rgb(255, 154, 154) !important;
    font-weight: bold !important;
    font-size: 10px !important;
    width: 100%;
    max-width: 140px;
    border-radius: 12px !important;
    height: 40px;
    background-color: white;
}

.student-remove-button:hover {
    background-color: rgba(255, 154, 154, .1) !important;
}

.delete-student-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

.modal-content-class {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.modal-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    margin:4rem 0 1rem;
}

.delete-modal-cancel-button {
    box-shadow: 0px 0px 4px #b1b1ff;
    border: 1px solid #b1b1ff !important;
    color: blue !important;
    font-weight: bold !important;
    font-size: 14px !important;
    width: 100%;
    /* max-width: 150px; */
    border-radius: 12px !important;
    height: 50px;
    background-color: white;
}
.delete-modal-confirm-button {
    box-shadow: 0px 0px 4px rgb(255, 163, 163) !important;
    border: 1px solid rgb(255, 154, 154) !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 14px !important;
    width: 100%;
    /* max-width: 150px; */

    border-radius: 12px !important;
    height: 50px;
    background-color: rgb(255, 63, 63) !important;
}
.delete-modal-confirm-button:hover {
    background-color: rgb(255, 154, 154) !important;
}
@media screen and (min-width: 501px) {
    .modal-buttons-container {
        flex-direction: row;
        justify-content: space-between;
        /* justify-content: end; */
        gap:1rem;
    }
    .delete-modal-cancel-button{
        max-width: 150px;
    }
    .delete-modal-confirm-button{
        max-width: 150px;
    }
}

.modal-warning-text{
    color: red;
    font-weight: bold;
}