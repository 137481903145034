.return-button{
    border-radius: 12px !important;
    height: 40px;
    width: 100%;
    max-width: 200px;
    background-color: white;
    color: blue !important;
    font-weight: bold !important;
    font-size: 10px !important;
    box-shadow: 0px 0px 4px #b1b1ff;
    border: 1px solid #b1b1ff !important;
    margin-top: 5rem !important;
}
