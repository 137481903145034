.css-2613qy-menu {
   z-index: 999 !important;
   height: 50px !important;
}

.question-label {
   font-family: Open Sans !important;
   opacity: 1 !important;
   background: #fff !important;
   margin-left: 15px;
   font-size: 16px !important;
   line-height: 16px !important;
   color: #8d8d8d !important;
}

.svg-color-green {
   stroke: green !important;
}

.css-2b097c-container,
.css-14jk2my-container {
   border-bottom: 1px solid #ced4da !important;
}

.css-11hpcgx-control,
.css-1hivdsp-control,
.css-hr4vws-control {
   border: none !important;
}
.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator {
   display: none !important;
}

.css-6q0nyr-Svg {
   color: black;
   height: 16px;
}

.css-1gi1mx2-ValueContainer {
   padding-left: 12px !important;
}
