.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    /* gap: 10px; */
    width: 90%;
    /* margin: auto; */
    margin-top: 5vh;
    border-bottom: 3px solid #9932CC;
    margin-bottom: 10vh;
}
.grid-item {
    padding: 20px;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
}

.class-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 150px; */
    text-align: center;
  }
  
  .class-info-item {
    border: 2px solid #dee2e6;
    padding: 1rem;
    margin: auto;
    width: 100%;
  }
  
  .class-info-title {
    font-size: 1.25rem;
  }
  
  /* Media query for flex row between 551px and 1400px */
  @media screen and (min-width: 500px) and (max-width: 1400px) {
    .class-info-container {
      flex-direction: row;
      justify-content: space-evenly;
    }
  }