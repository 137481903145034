.finishing-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.finishing-page-left-buttons {
    width: 20%;
    float: left;
    border-right: 1.3px solid rgb(220, 217, 217);
}

.finishing-page-info {
    width: 75%;
    float: right;
    height: 60vh;
}

.finishing-page-info h3 {
    text-align: center;
    margin-top: 7rem;
    font-size: 40px;
    color: blue;
    font-weight: bold;
}

.finishing-page-info p {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10rem;
}

.refresh-warning {
    color: #B1B1FF;
    font-size: 6;
    text-align: center;
    margin-top: -120px;
}

.pre-finish-icons-list-bottom {
    margin-top: -30px;
}

.btn-next {
    background-color: blue;
    color: white;
    border-radius: 15px;
    border: 0;
    cursor: pointer;
    font-family: inherit;
    font-weight: bold;
    padding: 8px 30px;
    margin-left: 20rem;
    font-size: 14px;
}


.btn:active {
    transform: scale(0.97);
}

.btn-prev {
    background-color: white;
    color: blue;
    border-radius: 15px;
    border: 2px solid blue;
    cursor: pointer;
    font-family: inherit;
    font-weight: bold;
    padding: 8px 30px;
    margin: 5px;
    font-size: 14px;
}

.btn-prev:disabled {
    background-color: grey;
    cursor: not-allowed;
    border: 2px solid grey;
    color: white
}

.btn-next:disabled {
    background-color: grey;
    border: 2px solid grey;
    color: white
}

.circle-1{
    width: 3rem;
    height: 3rem;
    line-height: 45px;
    background-color: #D5D5FF;
    color: white;
    text-align: center;
    justify-content: center;
    border: 0.1px solid blue;
    border-radius: 35%;
    font-weight: bold;
}

.stepWrapper {
    display: flex;
    margin-bottom: 20px;
}

.circleWraper .stepBlock {
    padding: 0px 20px;
    position: relative;
}

.selected .circle-1 {
    color: white;
    background-color: blue;
}

.circle-1 {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.pre-finish-footer-quiz {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18%;
}   