.teacher-schedule-container {
    min-height: 78vh;
    margin-top: 6vh;
    position: relative;
}

.schedule-container {
    padding: 0vw 6vw 0vw 6vw;
}

.preview-button {
    border-radius: 12px;
    height: 40px;
    width: 120px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    box-shadow: 0px 0px 16px #B8B8ED33;
    border: 1px solid #B1B1FF;
}

.delete-button {
    border-radius: 12px;
    height: 40px;
    width: 120px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    box-shadow: 0px 0px 16px #B8B8ED33;
    border: 1px solid #ff5555;
}

.teacher-footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .teacher-footer {
        position: relative; 
        transform: none; 
        left: 0;
    }
}
.schedule-table-container {
    margin-top: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto ;
    height: 25.3rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: 20;
}

.schedule-buttons-container {
    align-items: center;
    display: flex;
    width: 100%;
}

.create-class-button {
    width: 50%;
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-right: 5vw;
}

.see-classroll-button {
    width: 50%;
    float: right;
    display: flex;
    justify-content: flex-start;
    margin-right: 5vw;
}

.classes-buttons {
    border-radius: 12px;
    height: 40px;
    width: 160px;
    margin: auto;
    background-color: white;
    color: blue;
    font-weight: bold;
    font-size: 11px;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
}

.classes-buttons-1 {
    border-radius: 12px;
    height: 40px;
    width: 120px;
    margin: auto;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 11px;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
}

.schedule-icon-class {
    font-size: 20px;
    margin-right: 1vw;
}

.dialog-body-delete {
    height: 30vh;
    width: 40%;
}

.registration-page-container-content-form-style {
    width: 100%;
    min-width: 90vw;
    padding: 2.5rem 0;
}
@media screen and (min-width: 450px) {
    .registration-page-container-content-form-style {
        min-width: 400px;
    }
}
