.header-student {
    height: 18vh;
    display: flex;
    background-color: rgb(248, 248, 255);
    border-radius: 20px;
}

.header-student-logo {
    background-color: white;
    width: 20%;
    height: inherit;
    text-align: center;
    float: left;
    border-bottom: 1.3px solid rgb(220, 217, 217);
    border-top-left-radius: 20px;
}

.header-student-logo img {
    justify-self: center;
    align-self: center;
}

.header-student-info {
    width: 80%;
    background-color: transparent;
    float: right;
    display: flex;
    border-bottom: 1.3px solid rgb(220, 217, 217);
}

.quiz-header-info{
    width: 70%;
    float: left;
}

.quiz-header-info p {
    font-size: 10px;
}

.quiz-header-image {
    width: 30%;
    float: right;
}

.quiz-header-image h6 {
    margin-top: 25px
}

.quiz-header-image span {
    color: grey
}

.grade-info {
    display: inline-block;
    min-width: 5rem;
    height: 2rem;
    text-align: center;
    background: white;
    border: 2px solid rgb(134, 170, 243);
    margin-left: 2rem;
    border-radius: 0.25cm;
    color: blue;
    vertical-align: middle;
    line-height: 25px;
    font-size: 10px;
    margin-top: 1rem;
}


.logout-button {
    border-radius: 12px;
    height: 32px;
    width: 90px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 16px #B8B8ED33;
    border: 1px solid #B1B1FF;
}

