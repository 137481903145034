.results-container {
    margin-top: 6vh;
    position: relative;
}

.back-results {
    margin-top: 3vh;
    margin-left: 5vw;
    margin-bottom: 5vh;
}

.no-errors {
    margin-left: 5vw;
    margin-top: 3vh;
}

.results-table-container {
    margin-top: 2rem;
    padding-left: 4vw;
    padding-right: 4vw;
    height: 30rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: 20;
}


.back-button-results {
    border-radius: 12px;
    height: 40px;
    width: 160px;
    background-color: white;
    color: blue;
    font-weight: bold;
    font-size: 10px;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
    margin-bottom: 4vh;
}

.results-answers {
    margin-top: 5vh;
    border: 2px solid #EAEAFF;
    border-radius: 20px;
    box-shadow: 0 2px 2px #DEDEDE;
    text-align: center;
    margin-bottom: 2vh;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.answers-title {
    font-size: 40px;
    margin-top: 5vh;
}

.answers-content {
    width: 70%;
    text-align: justify;
    margin: auto;
    border-top: 2px solid #EAEAFF;
}

.results-footer {
    padding-bottom: 2vh;
}