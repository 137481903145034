/* .quiz-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.past-quizzes-info {
    width: 75%;
    margin-left: 2rem;
    height: 70vh;
}

.past-quizzes-icon span {
    color: black;
    font-weight: bold;
    margin-left: 2rem;
}

.current-quizzes-icon span {
    color: black;
    font-weight: bold;
    margin-left: 2rem;
}

.icons-past-past {
    color: grey;
    height: 3rem;
    width: 3rem;
    background-color: #F2F2FF;
    border-radius: 43%;
    text-align: center;
    vertical-align: middle;
    padding: 1rem;
    margin-left: -0.8rem;
    margin-right: -1.2rem;
}

.icons-current-past {
    color: grey;
    background-color: white;


}

.icons-list {
    margin-top: 2rem;
}

.current-quizzes-icon:hover {
    text-decoration: none;
}

.past-quizzes-icon:hover {
    text-decoration: none;
}

.icons-help-contact {
    color: grey;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
}

.back-button-1 {
    border-radius: 12px;
    height: 32px;
    width: 150px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
    margin-top: 10px;
    margin-left: -20px;
}

.back-button-2 {
    border-radius: 12px;
    height: 38px;
    width: 150px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
    margin-top: 50vh;
}

.empty-table {
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.empty-table h4 {
    color: grey;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
}

.empty-table h6 {
    color: grey;
    justify-content: center;
    text-align: center;
}

.logout-icon-past {
    color: grey;
    background-color: transparent;
    border: none;
    margin-left: -0.2rem;
}

.help-contact-icon span {
    color: black;
    font-weight: bold;
    margin-left: 2rem;
}

.logout-icon-past span {
    color: black;
    font-weight: bold;
    margin-left: 1.9rem;
    font-size: 12px;
}


.icons-list-bottom {
    margin-top: 20rem;
}

.class-list-table-quiz span {
    color: blue;
    font-weight: bold;
}

.class-list-table-crt {
    vertical-align: bottom;
    width: 10%;
}

.class-list-table-quiz {
    width: 10%;
}

.class-list-table-container {
    margin-top: 4rem;
    width: 75% !important;
    margin-left: auto;
    margin-right: auto;
    height: 20rem;
    overflow-y: scroll;
}

.class-list-table-date-recent, 
.class-list-table-number-recent, 
.class-list-table-score-recent, 
.class-list-table-button {
    width: 15%;
}

.class-list-table-head {
    position: sticky;
    top: 0;
}

*/
.addclass-button {
    border: 2px solid rgb(193, 176, 218);
    border-radius: 0.3cm;
    height: 40px;
    width: 120px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 16px;
} 

.class-list-rows{
    background-color: black !important;
}

.class-list-table{
    width:75%;
    margin-left:2rem;
    height:70vh;
}

.class-list-table-container{
    margin-top:4rem;
    margin-left:2rem;
    height:20rem;
    scrollbar-width:none;
    overflow-y:scroll;
    margin-right:auto;
}

.class-list-table-head{
    position:sticky;
    top: 0;
}

.class-list-table-number{
    width:10%;
}
.class-list-table-classname{
    width:25%;
}
.class-list-table-grade{
    width:10%;
}

.class-list-table-subject{
    width:30%;
}

.class-list-table-year{
    width:10%;
}
.class-list-table-status{
    width:15%;
}