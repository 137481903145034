@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.App {
  text-align: center;
}

body {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
}

img {
  max-width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pointerType-new {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.pointerType {
  cursor: pointer;
}

.active-page span {
  color: orange !important;
}

.z-negative {
  z-index: -1;
}

.roundimage {
  display: block;
  width: 70px;
  height: 50px;
  background: #e6e7ed;
  text-align: center;
  align-content: center;
  align-items: center;
  /* border-radius: 50%; */
  /* -moz-border-radius: 50%;
	-webkit-border-radius: 50%; */
}
.roundimage img {
  max-height: 100%;
  max-width: 100%;
}

.btn-custom {
}

.transparent-btn {
  background-color: transparent !important;
  color: rgba(141, 141, 141, 0.6) !important;
}

.btn-custom {
  border: none;
  border-radius: 1.6px;
  border: 0.8px solid #e5e2e2 !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14.4px;
  line-height: 20px;
  text-align: center;
  width: 168px;
  padding: 10px 0px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clever-login-button{
  border:none;
  background: none;
  /* margin-bottom: 38px; */
  height:fit-content;
  width:fit-content;
  /* margin-left: 23%; */
  margin-bottom: 40px;
}

.clever-login-button img{
  max-height: 65px;
}

/*logo design css*/
.logo {
  padding-top: 64px;
  padding-bottom: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.link:hover,
.link:focus {
  color: #0056b3;
  text-decoration: underline;
}

.align-top {
  margin-top: -10px;
}

.notification-icon {
  margin-top: -2px;
}

/*login page css start here*/

.form-container {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  margin: 0 auto;
  font-family: Open Sans;
}

h1.form-heading {
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  color: #333333;
  padding: 25px 0;
  font-family: Open Sans;
  margin-bottom: 0 !important;
}

.form-container form input.input-style {
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #e5e5e5;
  border-radius: 24px;
  height: 48px;
  margin-bottom: 38px;
  padding-left: 13px;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  mix-blend-mode: normal;
  font-family: Open Sans;
  padding-right: 50px;
  z-index: 999;
}

input:focus ~ .floating-label,
.label-up {
  top: -13px;
  bottom: 10px;
  font-family: Open Sans;
  left: 25px;
  opacity: 1;
  background: #fff;

  padding: 5px;
  line-height: 16px;
  color: #8d8d8d;
}

.floating-type {
  font-family: Open Sans;
  color: #8d8d8d !important;
  font-size: 12px;
  opacity: 1;
  background: #fff;
}

/*input:not(:focus):valid ~ .floating-label {
  top: -16px;
  bottom: 10px;
  left: 25px;
  font-size: 14px;
  opacity: 1;
  background: #fff;
  height: fit-content;
  padding: 5px;
}
*/

/* @-moz-document url-prefix() {
	.floating-label {
		height: 15px !important;
	}
} */

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 10px;
  transition: 0.2s ease all;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  mix-blend-mode: normal;
  opacity: 0.6;
  font-family: Open Sans;
  height: 0px;
}

.form-container form {
  padding: 0 48px;
}

button.custom-btn {
  border: 1px solid #e5e2e2;
  font-family: Open Sans;
  border-radius: 23px;
  width: 100%;
  background-color: inherit;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #b9b9b9;
  margin-bottom: 38px;
  height: 48px;
}

.login-text {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #8d8d8d;
  margin-bottom: 35px;
  font-family: Open Sans;
}

.text-orange {
  color: #f1a139;
  font-family: Open Sans;
}

.relative {
  position: relative;
  font-family: Open Sans;
}

.have-issue-text p {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #8d8d8d;
  padding-top: 35px;
  font-family: Open Sans;
}

.fluid.footer {
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #b9b9b9;
  left: 0;
  font-family: Open Sans;
}

.eye-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  font-family: Open Sans;
}

.error-msg {
  margin: 20px auto;
  font-family: Open Sans;
}

.error-msg p {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ff3d3d;
  margin-bottom: 0;
  font-family: Open Sans;
}

/*signup page css start here*/
.signup-checkbox {
  display: flex;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  margin-bottom: 38px;
  font-family: Open Sans;
}

/* Hide the browser's default checkbox */
.signup-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  left: 20px;
  top: -5px;
  z-index: 9;
  font-family: Open Sans;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #b9b9b9;
  border-radius: 2px;
  font-family: Open Sans;
}

.signup-checkbox label {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.1px;
  color: #8d8d8d;
  font-family: Open Sans;
}

.signup-checkbox span.tou {
  font-size: 13px;
  font-family: Open Sans;
}

/* When the checkbox is checked, add a blue background */
.signup-checkbox input:checked ~ .checkmark {
  background-color: #f1a139;
  border-radius: 2px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.signup-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.signup-checkbox .checkmark:after {
  left: 5px;
  top: 0;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.instruction-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #8d8d8d;
  margin-bottom: 38px;
}

.reset-pwd {
  margin-bottom: 70px !important;
}

.second-msg {
  padding: 0 48px;
}

/*Contactus popup design start here*/
.contactus-popup {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.contactus-popup .modal-dialog {
  width: 550px;
  max-width: 100%;
}

.contactus-popup .modal-header,
.term-use .modal-header {
  padding-top: 37px;
  padding-bottom: 27px;
  border-bottom: 0;
  position: relative;
}

.modal-header .close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.contactus-popup .modal-title,
.term-use .modal-title {
  text-align: center;
  width: 100%;
}

.contactus-popup .modal-body {
  padding: 0 57px;
}

.contactus-popup .modal-footer {
  border-top: 0;
  padding: 0 48px;
}

input[type="textarea"] {
  min-height: 120px !important;
}

.cus {
  height: 120px !important;
  resize: none;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #e5e5e5;
  border-radius: 24px !important;
  height: 48px;
  margin-bottom: 38px;
  padding-left: 13px;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  mix-blend-mode: normal;
  font-family: Open Sans;
}

textarea:focus ~ .floating-label,
.label-up ~ .floating-label {
  top: -13px;
  bottom: 10px;
  font-family: Open Sans;
  left: 25px;
  opacity: 1;
  background: #fff;

  padding: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #8d8d8d;
}

form input.input-style {
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #e5e5e5;
  border-radius: 24px;
  height: 48px;
  margin-bottom: 38px;
  padding-left: 13px;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  mix-blend-mode: normal;
  font-family: Open Sans;
}

input:focus ~ .floating-label,
.label-up ~ .floating-label {
  top: -13px;
  bottom: 10px;
  font-family: Open Sans;
  left: 25px;
  opacity: 1;
  background: #fff;
  /* height: fit-content; */
  padding: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #8d8d8d;
}

/*input:not(:focus):valid ~ .floating-label {
  top: -16px;
  bottom: 10px;
  left: 25px;
  font-size: 14px;
  opacity: 1;
  background: #fff;
  height: fit-content;
  padding: 5px;
}
*/

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 10px;
  transition: 0.2s ease all;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  mix-blend-mode: normal;
  opacity: 0.6;
  font-family: Open Sans;
}

.contactus-popup .modal-content {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}

/*terms of use css start here*/

.term-use .modal-dialog {
  max-width: 100%;
  width: 550px;
}

.term-use .modal-body {
  padding: 0 48px;
}

.second-heading-para p:nth-child(1) {
  padding-bottom: 20px;
}

.second-heading-para p:nth-child(2) {
  font-weight: 600;
  padding-bottom: 30px;
}

.second-heading-para p {
  font-family: Open Sans;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.115385px;
  color: #333333;
  margin-bottom: 0;
}

.body-content ul {
  padding-left: 0;
}

.body-content ul li {
  font-family: Open Sans;
  list-style: none;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.1px;
  color: #000000;
  padding-bottom: 10px;
}

.bottom-text p,
.bottom-text-2 p {
  margin-bottom: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.1px;
  color: #333333;
}

p.form-text-danger {
  bottom: -35px;
  left: 15px;
}

.text-danger {
  position: absolute;
  bottom: -22px;
  left: 20px;
  text-align: left;
  font-size: 14px;
}

.bottom-text-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.term-use .modal-header {
  padding-right: 48px;
  padding-left: 48px;
}

.term-use {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

input:focus {
  box-shadow: inherit !important;
}

/*@media screen and (max-width: 1199px) {
  .term-use {
    top: 0 !important;
  }
}
*/

.custom-btn:focus,
.save-btn:focus {
  outline: none !important;
  box-shadow: inherit !important;
}

.custom-btn:hover,
.custom-btn:focus,
.save-btn:hover,
.save-btn:focus {
  background-color: #3c7ebf !important;
  border-color: #3c7ebf !important;
}

.cancel-btn:focus {
  box-shadow: inherit !important;
}

.cancel-btn:hover {
  background-color: inherit !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

/*Pagination css start here*/
.page-item .pagination-link:focus,
.page-item .pagination-link:hover {
  box-shadow: inherit;
  background-color: inherit;
  color: #8d8d8d;
}

.page-item .pagination-link {
  padding-right: 17px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #8d8d8d;
}

.page-item .pagination-link.active {
  color: #333333;
}

/*delete popup css start here*/

.delete-modal .modal-dialog {
  max-width: 441px;
}

.delete-modal .modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 46px;
  padding-bottom: 17px;
}

.delete-modal .modal-dialog .modal-header .modal-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  color: #333333;
}

.user-type-content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #000000;
  margin-bottom: 30px;
}
body .delete-modal .modal-dialog .modal-content {
  border-radius: 12px;
}

body .delete-modal .modal-dialog .modal-footer {
  border-top: 0;
  padding: 0 30px 36px 30px;
  justify-content: space-between;
}

body .popup-close-btn:focus {
  box-shadow: inherit !important;
  background-color: inherit !important;
  border-color: inherit !important;
}

body .popup-save-btn:focus {
  box-shadow: inherit !important;
  background: #3c7ebf !important;
  color: #ffffff !important;
}

body .popup-close-btn:focus {
  color: #000000 !important;
  opacity: 0.6 !important;
}

body .popup-close-btn,
body .popup-close-btn:hover {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14.4px;
  line-height: 20px;
  text-align: center;
  mix-blend-mode: normal;
  color: #000000;
  opacity: 0.6;
  background-color: transparent;
  border: transparent;
  padding: 8px 45px;
}

body .popup-save-btn,
body .popup-save-btn:hover {
  background: #3c7ebf;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  border-radius: 23px;
  padding: 8px 61px;
}

.close:focus {
  outline: 0;
}

/*main dashboard page css start here*/
.dashboard-heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.115385px;
  color: #9d9d9d;
}

.item-list-view .card {
  width: 100% !important;
  border-radius: 0px 19px 0px 0px;
  border-color: #e0e0e0 !important;
}

.item-list-view .card-header {
  background-color: transparent;
  border-bottom: none;
  float: right;
  text-align: right;
  position: relative;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  text-transform: capitalize;
  color: #979797;
}

.item-list-view .card-header:before {
  content: " ";
  position: absolute;
  height: 10px;
  width: 10px;
  border: 1px solid #979797;
  border-radius: 50%;
  left: 20px;
}

.item-list-view .card-body .card-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 57px;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}

.item-list-view .card-body {
  padding: 16px !important;
  position: absolute;
  bottom: 0;
}

.created .card-title {
  color: #3c7ebf;
}

.approved .card-title {
  color: #6fcf97;
}

.pending .card-title {
  color: #bdbdbd;
}

.rejected .card-title {
  color: #eb5757;
}

.authors .card-title {
  color: #9b51e0;
}

.reviewers .card-title {
  color: #56ccf2;
}

.user-name {
  display: flex;
  justify-content: center;
  margin-top: -8px;
}

.logout {
  font-size: 15px;
}

.create-password-field input {
  margin-bottom: 60px !important;
}

.create-password-field .text-danger {
  bottom: -40px !important;
}

.setting-input .ql-editor {
  height: 160px !important;
}

.setting-input .ql-snow,
.border-color {
  border-color: #e5e2e2 !important;
}

.label-color {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #828282;
  margin-bottom: 0;
}

body .setting-input-style {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding-left: 0 !important;
}

.contact-us-email-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.115385px;
  color: #333333;
  height: 100%;
  align-items: center;
  display: flex;
}

.align-self {
  align-self: center;
}

.alignment {
  align-items: center;
}

.standard-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.115385px;
  color: #333333;
  padding-left: 24px;
  padding-right: 40px;
}

body .alignment .upload-excel {
  border: 1px solid #3c7ebf;
  border-radius: 55.4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3c7ebf;
  padding: 10px 80px;
}

body .alignment .set-hover-true:hover {
  color: #fff;
  background-color: #3c7ebf;
}
body .alignment .set-hover-false:hover {
  color: #3c7ebf;
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  nav.navbar-nav,
  div.navbar-nav {
    flex-direction: unset !important;
  }

  .logout {
    font-size: 10px;
    padding-top: 3px;
  }

  html body .navbar-style a,
  html body .navbar-style a.active {
    font-size: 12px !important;
  }

  .user-name {
    font-size: 12px !important;
  }

  .notification-icon:after {
    right: 0;
    top: 2px;
  }

  .user-name {
    margin-top: -5px;
  }
  body .ipad-create-user-icon {
    width: 33px;
    padding-left: 8px;
  }
}

@media screen and (max-width: 767px) {
  .term-use .modal-body {
    padding: 0 18px;
  }

  .term-use .modal-header {
    padding-left: 18px;
    padding-right: 18px;
  }
  body .mob-padding {
    padding: 0 !important;
  }
}

.dragged {
  position: static !important;
  color: #f1a139 !important;
}

.modal-90w {
  width: 60% !important;
  max-width: none !important;
}

.modal-30w {
  width: 30% !important;
  max-width: none !important;
}
.gnwqMi {
  width: 100px;
}
.__se__p-neon {
  font-weight: 200;
  font-style: italic;
  background: #000;
  color: #fff;
  padding: 6px 4px;
  border: 2px solid #fff;
  border-radius: 6px;
  text-transform: uppercase;
  animation: neonFlicker 1.5s infinite alternate;
}
.sun-editor .se-dialog .se-dialog-inner {
  z-index: 1006;
}
.sun-editor .se-dialog .se-dialog-back {
  z-index: 1005;
}
.se-container {
  z-index: 100002;
}
