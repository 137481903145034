
.quiz-questions-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.questions-page-left-buttons {
    width: 20%;
    float: left;
    border-right: 1px solid rgb(224, 222, 222);
}

.questions-page-info {
    width: 75%;
    margin-left: 2rem;
    height: 70vh;
}
.left{
    font-size: 35px;
    color: #B1B1FF;
    font-size: 40;
    font-family: 'Apple Chancery, cursive';
    padding-left: 20px;

}

.question-title {
    text-align: center;
    color: blue;
    font-weight: bold;
    font-size: 20px;
    margin-top: -2rem;
}

.control-form-1 {
    width:100%;
    height: 82%;
    overflow-y: scroll;

}

.error-msg {
    color: red;
    margin-top: -2%;
}

.radio-buttons {
    align-self: center;
}

.control-label {
    color: black;
}



.btn-next-1 {
    background-color: white;
    color: blue;
    border-radius: 12px;
    border: 2px solid blue;
    cursor: pointer;
    font-family: inherit;
    font-weight: bold;
    font-size: 14px;
    height: 40px;
    width: 150px;
}


.btn-1:active {
    transform: scale(0.97);
}

.btn-prev-1 {
    background-color: white;
    color: blue;
    border-radius: 12px;
    height: 40px;
    width: 140px;
    background-color: white;
    color: grey;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    box-shadow: 0px 0px 30px #B8B8ED33;
    border: 1px solid #B1B1FF;
}

.btn-prev-1:disabled {
    background-color: grey;
    cursor: not-allowed;
    border: 2px solid grey;
    color: white
}

.btn-next-1:disabled {
    background-color: grey;
    border: 2px solid grey;
    color: white
}




.footer-quiz {
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 18%;
    margin-top: 2rem;
}

.back-quiz-button {
    margin-left: -10rem;
    width: 30%;
    margin-left: 40px;
    float: left;
}

.next-quiz-button {
    margin-left: -10rem;
    width: 30%;
    float: right;
}

.stepper-number-container {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: grey;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    justify-content: space-between;
    margin: auto;
}

.stepper-number {
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
}

.stepper-line {

}

.stepper-container {
    width: 100%;
    display: flex;
    margin: auto;
    position: relative;
}

.stepper-display-steps {
    display: flex;
    align-items: center;
}

.progress-bar {
    display: flex;
    margin: 40px 0;
    flex-direction: row;
    width: 100%;
    background-color: white;
}

.step {
    background-color: white;
    margin: 4%;
}

.hr {
    border: 2px solid green;
    margin-top: 20px;
    z-index: -1;
    width: 100px;
}

.bullet {
    height: 3rem;
    width: 3rem;
    border: 2px solid #000;
    display: inline-block;
    border-radius: 34%;
    font-weight: 500;
    font-size: 17px;
    line-height: 2.5rem;
    text-align: center;
    z-index: 99999;
}

.bullet span {
    text-align: center;
    color: black;
    z-index: 99999;
}

.progress-bar .step.bullet:before,
.progress-bar .step .bullet:after {
    content: '';
    height: 1rem;
    width: 5.1%;
    background-color: #262626;
    margin-top: 1.2rem; 
    z-index: -1;
    z-index: 99999;
}

.progress-bar .progress .step:last-child .bullet:before,
.progress-bar .progress .step:last-child .bullet:after {
    display: none;
}

.progress-bar .step .bullet:after {
    background-color: #57577a;
    transform: scaleX(0);
    animation: animate 0.3s linear forwards;
    transform-origin: left;
    z-index: 99999;
}
@keyframes animate {
    100% {
        transform: scaleX(1);
    }
}

.icons-list-bottom-quiz-p{
    margin-top: 20rem;
}

