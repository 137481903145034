.title {
    margin-left: 12vw;
    margin-top: 10vh;
    width: 31%
}

.main {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-top: 10vh;
}

.admin-subtext {
    font-size: 23px;
    margin-left: 12vw;
    margin-top: 2vh;
}

.left {
    width: 25%;
}
  
.right {
    width: 75%;
}

.table-title {
    font-size: 25px;
    color: black;
    font-weight: bold;
    margin-left: 1vw;
    border-bottom: 2px solid #9932CC;
    font-family: 'Times New Roman', Times, serif;
}


.button-div {
    border: 2px solid #9932CC;
    width: 8vw;
    height: 5vh;
    float: right;
    margin-right: 13vw;
    margin-top: 10vh;
    box-shadow: 6px 6px #9932CC;
}

.save-button {
    width: 8vw;
    height: 5vh;
}