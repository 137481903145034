.title-teacher {
    margin-top: 5vh;
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    margin-left: 20vw;
    width: 38%;
}

.messages {
    margin-left: 25vw;
    font-size: 22px;
    margin-top: 2vh;
    font-weight: 500;
    margin-bottom: 9vh;
}

.second-p {
    margin-left: 5vw;
}

.main {
    display: flex;
    width: 80%;
    margin: auto;
}

.box {
    flex: 1;
}

.form-title {
    font-size: 20px;
    font-weight: bold;
}

.button-div {
    border: 2px solid #9932cc;
    width: 8vw;
    height: 5vh;
    float: right;
    margin-right: 18vw;
    margin-top: 5vh;
    box-shadow: 6px 6px #9932cc;
}

.save-button {
    width: 8vw;
    height: 5vh;
}

/* Registration Page Classes */

.registration-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
}
.registration-page-container-content {
  padding: 2.5% 5%;

}
@media screen and (min-width: 700px) and (min-height: 501px) {
    .registration-page-container-content {
        height: 100%;
        max-width:700px;
        max-height:fit-content;
        overflow: scroll;
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        margin: 5% auto;
    }
}

/* .registration-page-container {
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (min-width: 750px){
    .registration-page-container{
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        margin: 5% 15%;
    }
}
@media screen and (min-width: 1050px){
    .registration-page-container{
        margin: 5% 20%;
    }
}

.registration-page-container-continue-button{
    border: 1px solid #e5e2e2;
    font-family: Open Sans;
    border-radius: 23px;
    width: 100%;
    background-color: inherit;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #b9b9b9;
    margin-bottom: 38px;
    height: 48px;
  }
  .registration-page-container-continue-button:hover{
    color:white;
  } */
/* .registration-page-container {
    margin: 0 auto;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }
   @media screen and (min-height: 800px) and (min-width: 625px) {
    .registration-page-container {
    height: 100vh;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    }
   }
  
  @media screen and (min-width:800px){
    .registration-page-container-content {
      margin: 0 5%;
      padding: 0 5%;

    }
  }
  @media screen and (min-width:1051px){
    .registration-page-container-content {
      margin: 0 20%;
    }
  }
  
    .registration-page-container-continue-button{
      border: 1px solid #e5e2e2;
      font-family: Open Sans;
      border-radius: 23px;
      width: 100%;
      background-color: inherit;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #b9b9b9;
      margin-bottom: 38px;
      height: 48px;
    }
    .registration-page-container-continue-button:hover{
      color:white;
    }
  .registration-page-container-content-form{
    width: 100%;
    min-width: 300px;
    padding: 2.5rem 0;
  }
  @media screen and (min-width:450px){
    .registration-page-container-content-form {
      min-width: 400px;
    }
  } */
