.teacher-clever-students-content {
    display: grid;
    margin-inline: auto;
    grid-template-columns: repeat(8, 1fr);
    margin-top: 2.5rem;
    gap: 1rem;
    grid-template-areas:
        'button title title title title title title title'
        'header-table table-content table-content table-content table-content table-content table-content table-content';
    padding-left: 1rem;
    padding-right: 1rem;
}
@media screen and (min-width: 1400px) {
    .teacher-clever-students-content {
        margin-top: 1rem;
    }
}
@media screen and (max-width: 600px) {
    .teacher-clever-students-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        /* margin:1rem auto 0; */
        grid-template-areas:
            'button'
            'header-table'
            'title'
            'table-content';
        /* place-items: center; */
        /* overflow: hidden; */
    }
    .back-button-container {
        max-width: 200px;
    }

    .table-content {
        width: 100%;
    }
}

@media screen and (min-width: 601px) and (max-width: 1400px) {
    .teacher-clever-students-content {
        margin-top: 0;
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
            'header-table header-table header-table header-table header-table header-table'
            'button title title title title title'
            'table-content table-content table-content table-content table-content table-content';
    }
    .back-button-container {
        min-width: 140px;
        max-width: 140px;
    }
    .table-title-wrapper {
        margin-left: -140px;
    }
    .table-title-wrapper > div {
        max-width: 300px;
    }
}

.back-button-container {
    margin-top: auto;
    margin-bottom: auto;
    grid-area: button;
    width: 100%;
}

.teacher-clever-header {
    grid-area: header-table;
}

.table-title-wrapper {
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: title;
}
.table-content {
    grid-area: table-content;
    /* width:100%; */
}

.main-teacher-students {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
}

.left-teacher-1 {
    width: 65%;
    margin: auto;
    height: 40vh;
    overflow-y: scroll;
}

.left-teacher-2 {
    width: 65%;
    margin: auto;
    height: 48vh;
    overflow-y: scroll;
}

.right {
    width: 75%;
}

.table-title-first {
    font-size: 23px;
    color: black;
    font-weight: bold;
    margin-left: 1vw;
    margin-bottom: 50px;
    font-family: 'Times New Roman', Times, serif;
}

.table-title-teacher {
    font-size: 22px;
    color: black;
    font-weight: bold;
    margin-left: 1vw;
    display: inline-block;
    font-family: 'Times New Roman', Times, serif;
}

.validated-title {
    font-size: 22px;
    color: black;
    font-weight: bold;
    float: right;
    margin-right: 2vw;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

.table-subtitle {
    font-size: 25px;
    color: black;
    font-weight: bold;
    margin-left: 1vw;
    border-bottom: 2px solid #9932cc;
}

.save-enroll {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    /* width: 10%; */
    text-align: center;
}

.save-enroll-button {
    border-radius: 12px;
    height: 40px;
    width: 160px;
    background-color: white;
    color: blue;
    font-weight: bold;
    font-size: 10px;
    box-shadow: 0px 0px 4px #b1b1ff;
    border: 1px solid #b1b1ff;
}

.back-button-t-students {
    border-radius: 12px !important;
    height: 40px;
    width: 100%;
    background-color: white;
    color: blue !important;
    font-weight: bold !important;
    font-size: 10px !important;
    box-shadow: 0px 0px 4px #b1b1ff;
    border: 1px solid #b1b1ff !important;
}




.explanation-span{
    font-size: 1.5rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}


.explanation-span:hover{
    text-decoration: underline;
    cursor: pointer;
    /* opacity: 50%; */
}

.explanation-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

.explanation-modal-content{
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
}