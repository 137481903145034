.back-button {
    border-radius: 12px;
    height: 32px;
    width: 150px;
    background-color: white;
    color: blue;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    box-shadow: 0px 0px 4px #B1B1FF;
    border: 1px solid #B1B1FF;
    margin-bottom: 2rem;
}


.start-date {
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    color: grey;
    display: block;
    margin-bottom: 40px;
    margin-left: 20%;
}

.end-date {
    font-size: 16px;
    font-weight: bold;
    color: grey;
    display: block;
    margin-bottom: 40px;
    margin-left: 20%;

}


.table-retakes-container {
    border: 2px solid #B1B1FF;
    border-radius: 20px;
}

.start-calendar {
    width: 40%;
    float: 'left';
}

.end-calendar {
    width: 40%;
    float: 'right';
}

.teacher-schedule-left-buttons {
    background-color: white;
    width: 6%;
    height: 60vh;
    text-align: center;
    float: left;
    align-items: center;
}

.teacher-schedule-info {
    float: right;
    width: 69%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin: 4rem;
    margin-top: 1.2rem;
    height: 60vh;
}