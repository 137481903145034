.final-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.final-page-left-buttons {
    width: 20%;
    float: left;
    border-right: 1px solid rgb(224, 222, 222);
}

.final-page-info {
    width: 75%;
    margin-left: 2rem;
    height: 66vh;
}

.final-page-info p {
    font-weight: bold;
    margin-top: 1rem;
    font-size: 22px;
    color: grey;
    margin-left: 3rem;
}

.icons-false {
    color: red;
    height: 2rem;
    width: 2rem;
    border-radius: 0.25cm;
    text-align: center;
    vertical-align: middle;
}

.icons-correct {
    color: green;
    height: 2rem;
    width: 2rem;
    border-radius: 0.25cm;
    text-align: center;
    vertical-align: middle;
}

.correct-false-cell {
}

.last-page-table-body {
    position: sticky;
    top: 0;
    margin-right: 4rem;
}

.total-points {
    float: left;
    width: 50%;
    height: inherit;
    color: grey;
    font-weight: bold;
    font-size: 16px;
}

.total-points span {
    margin-left: 2.8rem;
}

.teachers-instructions {
    float: right;
    width: 50%;
    height: '10%';
    color: grey;
    text-align: center;
}

.last-page-crt,
.last-page-answers{
    width: 8%;
}

.last-page-status{
    width: 5%;
}

.last-page-points {
    width: 30%;
}

.past-table-container::-webkit-scrollbar {
    display: none;
}

.past-table-container {
    margin-top: 4rem;
    margin-left: 2rem;
    height: 25rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.exmaple::-webkit-scrollbar {
    display: none;
}

.icons-list-bottom-finish {
    margin-top: 20rem;
}

.finish-student-table-container {
    margin: 2rem;
}

.finish-student-table-row {
    margin-right: 20px;
}

.finish-student-table-head {
    margin-right: 20px;
}

.main-container {
    margin-left: 2rem;
    margin-right: 2rem;
}

.footer-final-page {
    margin-left: 3rem;
}

.return-button{
    border-radius: 12px !important;
    height: 40px;
    width: 100%;
    max-width: 200px;
    background-color: white;
    color: blue !important;
    font-weight: bold !important;
    font-size: 10px !important;
    box-shadow: 0px 0px 4px #b1b1ff;
    border: 1px solid #b1b1ff !important;
}

.return-button-container{
    margin-top: 2.5rem;
    width:100%;
    display:flex;
    justify-content: end;
}